<template>
<div class="studen_online_class_congratulation_page">
    <!-- banner-image  -->
    <div class="relative flex justify-center align-center flex-col" style="height: 70vh;">
        <span class="text-5xl font-bold text-algal">Congratulation</span>
        <div class="flex flex-col mt-10">
            <span class="text-base line-height-27">Online Class <strong>{{ getClassDetail?.title }}</strong></span>
            <span class="text-base text-center line-height-27">Has been Completed</span>
        </div>

        <div class="absolute" style="bottom: 0px;">
            <Button
                class="h-12 pointer text-transform-none bg-success rounded-xl  px-4 py-2 text-sm font-semibold text-white"
                type="button"
                label="Back to Online Class List"    
                @click.native="goToHome()"
            >
            </Button>
        </div>
    </div>
</div>
</template>

<script>
import { Button } from "@/components";
import { mapGetters } from 'vuex';
export default {
    name: "Congratulations",
    components: {
        Button,
    },
    computed:{
        ...mapGetters({
            getClassDetail: "onlineClassStudentPart/getClassDetail",
        }),
    },
    methods: {
        goToHome() {
            this.$router.push({
                name: 'student.onlineClass'
            });
        }
    }
}
</script>